import gql from'graphql-tag';import{calendarFragment,calendarToCustomerFragment}from'./Fragments';// TODO jednoznacne cez count a nenacitat vsetky calendars - rovno endpoint ktory vrati v jednom response pocet online a pocet cez appku alebo pripadne ich rovno v tejto query zavolat 2x count
export const typeOfCalendarsQuery=gql`
	query TypeOfCalendarsQuery(
		$subjectIds: [String!]
		$shopId: String
		$from: AWSDateTime
		$to: AWSDateTime
		$skip: Int
		$take: Int
	) {
		listCalendars(
			where: {shopId: {equals: $shopId}, subjectId: {in: $subjectIds}, from: {lte: $to}, to: {gte: $from}}
			orderBy: {from: DESC}
			skip: $skip
			take: $take
		) {
			id
			isOnlineReservation
		}
	}
`;export const listCalendarsQuery=gql`
	query ListCalendarsQuery(
		$shopId: String
		$states: [CalendarState!]
		$from: AWSDateTime
		$to: AWSDateTime
		$employeeWhereInput: EmployeeWhereInputWithoutNullables
		$take: Int
		$skip: Int
	) {
		listCalendars(
			where: {
				shopId: {equals: $shopId}
				state: {in: $states}
				to: {gte: $from}
				from: {lte: $to}
				employees: {some: $employeeWhereInput}
			}
			orderBy: [{from: ASC}]
			take: $take
			skip: $skip
		) {
			id
			eventType
			state
			from
			to
			note
			capacity
			subjectId
			customers {
				id
				name
				surname
				email
				phone
				subject {
					id
				}
				picture {
					id
					secret
				}
			}
			calendarToCustomers {
				id
				state
				payState
				capacity
				customer {
					id
					name
					surname
					email
					phone
					subject {
						id
					}
					picture {
						id
						secret
					}
				}
				bail {
					id
					state
					paidAt
					updatedAt
					refundUntil
					amount
				}
				bills {
					id
					state
					paidAt
				}
			}
			employees {
				id
				color
				subjectId
				userMyFox {
					id
					name
					surname
					email
					phone
				}
			}
			bills {
				id
				state
				paidAt
				note
			}
			carts {
				id
				name
				count
				priceVat
				item {
					id
					name
					price
					priceVat
					vat
					color
					duration
					pauseAfter
				}
			}
		}
	}
`;export const getCalendarQuery=gql`
	query GetCalendarQuery($calendarId: String) {
		getCalendar(where: {id: $calendarId}) {
			id
			from
			to
			state
			note
			capacity
			eventType
			paidAt
			subjectId
			carts {
				id
				name
				priceVat
				count
				item {
					id
					name
					duration
					pauseAfter
					priceVat
					price
					vat
					color
				}
			}
			bills {
				id
				note
				state
				paidAt
				totalPrice
			}
			shop {
				id
				timezone
			}
			subject {
				id
			}
			customers {
				id
			}
			calendarToCustomers {
				id
				capacity
				payState
				state
				bail {
					id
					state
					amount
					paidAt
					refundUntil
					updatedAt
				}
				customer {
					id
					name
					surname
					phone
					email
					userMyFox {
						id
					}
					picture {
						id
						secret
					}
				}
				calendar {
					id
				}
			}
			employees {
				id
				color
				subjectId
				shops {
					id
				}
				userMyFox {
					id
					name
					surname
				}
			}
		}
	}
`;export const customerOrEmployeeEventsQuery=gql`
	query EventsQuery($customersIds: [String!], $employeeId: String, $subjectsIds: [String!]) {
		events(where: {customersIds: $customersIds, employeeId: $employeeId, subjectsIds: $subjectsIds}) {
			id
			billId
			from
			dataOrigin
		}
	}
`;export const customerOrEmployeeEventsHistoryQuery=gql`
	query EventsHistoryQuery($customersIds: [String!], $employeeId: String, $subjectsIds: [String!]) {
		eventsHistory(where: {customersIds: $customersIds, employeeId: $employeeId, subjectsIds: $subjectsIds}) {
			id
			billId
			from
			dataOrigin
		}
	}
`;export const countCalendarsForStartLicenceQuery=gql`
	query CountCalendarsForStartLicenceQuery($subjectId: String!, $from: AWSDateTime!, $to: AWSDateTime!) {
		countCalendars(
			where: {
				from: {gte: $from, lte: $to}
				eventType: {in: [Individual, Group]}
				OR: [{subjectId: {equals: $subjectId}}, {employees: {some: {subjectId: {equals: $subjectId}}}}]
			}
		)
	}
`;export const createCalendarToCustomerMutation=gql`
	mutation CreateCalendarToCustomerMutation(
		$customerId: CustomerWhereUniqueInput
		$calendarId: CalendarWhereUniqueInput
		$createdBy: UserMyFoxWhereUniqueInput
		$capacity: Int
		$state: VisitState
	) {
		createCalendarToCustomer(
			data: {
				customer: {connect: $customerId}
				calendar: {connect: $calendarId}
				capacity: $capacity
				updatedBy: {connect: $createdBy}
				state: $state
			}
		) {
			...calendarToCustomer
		}
	}
	${calendarToCustomerFragment}
`;export const updateCalendarToCustomerMutation=gql`
	mutation UpdateCalendarToCustomerMutation(
		$id: String!
		$bill: BillWhereUniqueInput
		$billIds: [BillWhereUniqueInput!]
		$state: VisitState
		$payState: PayState
	) {
		updateCalendarToCustomer(
			where: {id: $id}
			data: {bill: {connect: $bill}, state: $state, payState: $payState, bills: {connect: $billIds}}
		) {
			...calendarToCustomer
		}
	}
	${calendarToCustomerFragment}
`;export const deleteCalendarToCustomerMutation=gql`
	mutation DeleteCalendarToCustomerMutation($id: String!) {
		deleteCalendarToCustomer(where: {id: $id}) {
			id
		}
	}
`;export const createCalendarMutation=gql`
	mutation CreateCalendarMutation(
		$from: AWSDateTime!
		$to: AWSDateTime!
		$state: CalendarState!
		$notifiesState: ProcessingState
		$shopId: String!
		$subjectId: String!
		$employeesIds: [EmployeeWhereUniqueInput!]
		$customersIds: [CustomerWhereUniqueInput!]
		$carts: [CartCreateWithoutCalendarInput!]
		$calendarToCustomers: [CalendarToCustomerCreateWithoutCalendarInput!]
		$note: String
		$eventType: EventType!
		$capacity: Int
	) {
		createCalendar(
			data: {
				from: $from
				to: $to
				state: $state
				notifiesState: $notifiesState
				shop: {connect: {id: $shopId}}
				subject: {connect: {id: $subjectId}}
				carts: {create: $carts}
				calendarToCustomers: {create: $calendarToCustomers}
				note: $note
				employees: {connect: $employeesIds}
				customers: {connect: $customersIds}
				eventType: $eventType
				capacity: $capacity
			}
		) {
			...calendar
		}
	}
	${calendarFragment}
`;export const updateCalendarMutation=gql`
	mutation UpdateCalendarMutation(
		$calendarId: String!
		$from: AWSDateTime
		$to: AWSDateTime
		$note: String
		$cartsIds: [CartWhereUniqueInput!]
		$employeesIds: [EmployeeWhereUniqueInput!]
		$customersIds: [CustomerWhereUniqueInput!]
		$carts: [CartCreateWithoutCalendarInput!]
		$state: CalendarState
		$notifiesState: ProcessingState
		$paidAt: AWSDateTime
		$payType: PayType
		$billNumber: String
		$subjectConnectId: SubjectWhereUniqueInput
		$eventType: EventType
		$capacity: Int
	) {
		updateCalendar(
			where: {id: $calendarId}
			data: {
				from: $from
				to: $to
				state: $state
				notifiesState: $notifiesState
				subject: {connect: $subjectConnectId}
				note: $note
				carts: {create: $carts, set: $cartsIds}
				employees: {set: $employeesIds}
				customers: {set: $customersIds}
				paidAt: $paidAt
				payType: $payType
				billNumber: $billNumber
				eventType: $eventType
				capacity: $capacity
			}
		) {
			...calendar
		}
	}
	${calendarFragment}
`;export const deleteBillDiscountMutation=gql`
	mutation DeleteCalPaymentMutation($id: String!) {
		deleteDiscount(where: {id: $id}) {
			id
		}
	}
`;export const updateCalendarBillMutation=gql`
	mutation UpdateCalendarBillMutation($calendarId: String!, $bill: CalendarUpdateInput!) {
		updateCalendar(where: {id: $calendarId}, data: $bill) {
			id
			bill
		}
	}
`;export const deleteCalendarMutation=gql`
	mutation DeleteCalendarMutation($calendarId: String!) {
		deleteCalendar(where: {id: $calendarId}) {
			id
			from
			state
		}
	}
`;export const loadBillQuery=gql`
	query LoadBillQuery($calendarId: String!) {
		getCalendar(where: {id: $calendarId}) {
			id
			bill
			billNumber
			subject {
				id
			}
		}
	}
`;export const loadBillJsonFromCtcQuery=gql`
	query LoadBillJsonFromCtcQuery($ctcId: String!) {
		getCalendarToCustomer(where: {id: $ctcId}) {
			id
			bill {
				billNumber
			}
			subject {
				id
			}
		}
	}
`;export const cancelMassMutation=gql`
	mutation CancelMassMutation($calendarsIds: [String!]!, $reason: String!) {
		cancelMass(data: {calendarsIds: $calendarsIds, reason: $reason}) {
			success
		}
	}
`;export const firstCalendarsForSubject=gql`
	query FirstCalendarsForSubject($subjectId: String!) {
		listCalendars(where: {subjectId: {equals: $subjectId}}, orderBy: {updatedAt: DESC}, take: 1) {
			id
			updatedAt
		}
	}
`;
import gql from'graphql-tag';export const listNotifiesByShopQuery=gql`
	query ListNotifiesQuery($shopId: String!) {
		listNotifies(where: {shopId: {equals: $shopId}}, orderBy: {sendAt: DESC}) {
			id
			contact
			shop {
				id
				name
			}
		}
	}
`;export const createNotificationMutation=gql`
	mutation CreateNotification(
		$contact: String!
		$sendAt: String!
		$subject: String!
		$body: String!
		$notifyPatternId: String!
		$shopId: String!
		$subjectId: String!
		$calendarInput: NotifyCreateInputConnectCalendar
		$billInput: NotifyCreateInputConnectBill
		$customerInput: NotifyCreateInputConnectCustomer
	) {
		createNotify(
			data: {
				contact: $contact
				sendAt: $sendAt
				subject: $subject
				body: $body
				notifyPattern: {connect: {id: $notifyPatternId}}
				shop: {connect: {id: $shopId}}
				subjectObject: {connect: {id: $subjectId}}
				calendar: $calendarInput
				bill: $billInput
				customer: $customerInput
			}
		) {
			id
		}
	}
`;export const createNotifyMutation=gql`
	mutation CreateNotifyMutation(
		$calendarId: CalendarWhereUniqueInput
		$billId: BillWhereUniqueInput
		$contact: String!
		$sendAt: AWSDateTime!
		$subject: String!
		$body: String!
		$conNotifyPattern: NotifyNotifyPatternCreateNestedInput!
		$conShop: NotifyShopCreateNestedInput!
		$conSubject: NotifySubjectObjectCreateNestedInput!
		$conCustomer: NotifyCustomerCreateNestedInput
		$state: ProcessingState
		$replyTo: String = ""
	) {
		createNotify(
			data: {
				calendar: {connect: $calendarId}
				bill: {connect: $billId}
				contact: $contact
				sendAt: $sendAt
				subject: $subject
				body: $body
				notifyPattern: $conNotifyPattern
				shop: $conShop
				subjectObject: $conSubject
				customer: $conCustomer
				state: $state
				replyTo: $replyTo
			}
		) {
			id
			processedAt
			subject
			contact
			subjectObject {
				id
			}
			notifyPattern {
				id
				event
				group
			}
		}
	}
`;export const deleteNotifyMutation=gql`
	mutation DeleteNotifyMutation($notifyId: String!) {
		deleteNotify(where: {id: $notifyId}) {
			id
		}
	}
`;export const sentSmsForPeriodQuery=gql`
	query SentSmsForPeriodQuery($subjectId: String!, $customerId: String!, $from: AWSDateTime!, $to: AWSDateTime!) {
		sentSmsForPeriod(where: {subjectId: $subjectId, stripeCustomerId: $customerId, from: $from, to: $to}) {
			success
			totalSms
			trialSms
			totalSmsWithoutWeight
			totalSmsWithoutTrial
			totalSmsToPay
			currentCountrySms
			euCountriesSms
			thirdCountriesSms
			smsPackage
			stripeCustomerId
			country
			isCountryInEU
			employees {
				id
				ord
				color
				fullName
				totalSentSms
			}
		}
	}
`;
import gql from'graphql-tag';export const changeOrderMutation=gql`
	mutation ChangeOrderMutation(
		$itemId: String!
		$shopId: String!
		$orderType: ChangeOrderType!
		$itemType: ItemTypes
		$order: Int!
	) {
		changeOrder(
			where: {itemId: $itemId, shopId: $shopId, orderType: $orderType, itemType: $itemType}
			data: {order: $order}
		) {
			message
			success
		}
	}
`;